<template>
  <v-navigation-drawer
    v-model="internalValue"
    :width="drawerWidth"
    right
    temporary
    fixed
    :touchless="isNavigatorOpen"
  >
    <v-dialog v-model="deleteCardSideModal" max-width="315px">
      <cart-delete-card
        @click:remove="invokeRemoveCartItem(currentSelectedItemId)"
        @click:wishlist="invokeAddToWishlist(currentSelectedItemId)"
        @click:cancel="deleteCardSideModal = false"
      ></cart-delete-card>
    </v-dialog>

    <slot name="top"></slot>

    <template v-if="!cartItems.length">
      <div class="d-flex" style="position: relative">
        <base-image
          :url="emptyCartUrl"
          alt=""
          style="height: auto; width: 100%"
        ></base-image>
        <div style="position: absolute" class="white--text pa-7 text-h1">
          {{ $t('siteCart.emptyCartText') }}
        </div>
      </div>

      <base-button
        v-if="!cartItems.length"
        outlined
        class="pink-berry--text d-flex font-weight-bold mx-3 mt-5"
        rounded
        style="border-width: 2px"
        :to="localePath('/')"
        @click.native="internalValue = false"
      >
        {{ $t('siteCart.browseNow') }}
      </base-button>
    </template>
    <template v-else>
      <v-slide-y-transition
        v-if="limitedLineItems.length > 0"
        group
        tag="div"
        class="py-2"
      >
        <cart-side-product-item
          v-for="item in limitedLineItems"
          :key="item.id"
          class="px-3 py-2"
          :item="item"
          :individualisation="individualisationItems[item.id]"
          @click:remove="handleRemoveCartItem"
        ></cart-side-product-item>
      </v-slide-y-transition>

      <div
        v-show="shouldShowMoreButton"
        class="text-center text-body-2 mb-4"
        role="button"
        @click="showAll = true"
      >
        {{ $t('siteCart.showAll') }}
        <base-icon class="d-block" mdi="chevron-down"></base-icon>
      </div>

      <cart-submit-promotion
        class="px-3"
        background-color="grey-lighten-4"
        @focus="uiEventFocusPromotion"
      ></cart-submit-promotion>

      <div class="mt-3">
        <cart-promotion-item
          v-for="code in appliedPromotionCodes"
          :key="code.id"
          :item="code"
          class="px-4"
        ></cart-promotion-item>
      </div>

      <base-button
        v-if="$vuetify.breakpoint.smAndDown"
        outlined
        class="grey-darken-4--text d-flex font-weight-bold mx-3 my-5"
        rounded
        style="border-width: 2px"
        :to="localePath('/cart')"
        @click.native="uiEventEditCart"
      >
        {{ $t('siteCart.editCart') }}
      </base-button>

      <cart-side-info-card
        v-if="$vuetify.breakpoint.mdAndUp"
        class="mt-7"
      ></cart-side-info-card>
    </template>

    <product-cross-selling-selector-container
      :cross-sellings="crossSelling"
      small-width
      title-class="mt-10 mb-8 text-h4 text-uppercase text-center"
      @click:product="uiEventClickCrossSelling"
    ></product-cross-selling-selector-container>

    <template #append>
      <cart-side-info-minimal-card
        v-if="$vuetify.breakpoint.smAndDown && cartItems.length > 0"
      ></cart-side-info-minimal-card>
      <base-button
        block
        large
        class="grey-lighten-3 text-capitalize"
        @click="handleContinueShopping"
      >
        <base-icon mdi="arrow-left" small class="mr-1"></base-icon>
        {{ $t('siteCart.continueShopping') }}
      </base-button>
    </template>
  </v-navigation-drawer>
</template>

<script>
import { computed, ref } from '@nuxtjs/composition-api'
import {
  useCart,
  useProductCrossSelling,
  useUiEvent,
  useUIState,
  useWishlist,
} from '~/composables'
import { NAVIGATION_DRAWER_STATE } from '~/services/UiStates'
import CartSideProductItem from '~/components/cart/side/CartSideProductItem'
import CartSubmitPromotion from '~/components/cart/CartSubmitPromotion'
import CartPromotionItem from '~/components/cart/CartPromotionItem'
import CartSideInfoCard from '~/components/cart/side/CartSideInfoCard'
import CartSideInfoMinimalCard from '~/components/cart/side/CartSideInfoMinimalCard'
import BaseIcon from '~/components/base/icon/BaseIcon'
import { getLineItemProductIndividualisations } from '~/helpers'
import BaseButton from '~/components/base/button/BaseButton'
import CartDeleteCard from '~/components/cart/CartDeleteCard'
import BaseImage from '~/components/base/image/BaseImage'
import ProductCrossSellingSelectorContainer from '~/components/product/ProductCrossSellingSelectorContainer.vue'

export default {
  name: 'CartSideDrawer',
  components: {
    ProductCrossSellingSelectorContainer,
    BaseImage,
    BaseButton,
    BaseIcon,
    CartDeleteCard,
    CartSideInfoMinimalCard,
    CartSideInfoCard,
    CartPromotionItem,
    CartSubmitPromotion,
    CartSideProductItem,
  },
  props: {
    value: {
      type: Boolean,
      default: false,
    },
  },

  setup(_, { root }) {
    const {
      cartItems,
      totalPrice,
      appliedPromotionCodes,
      cartProductItems,
      removeItem,
    } = useCart(root)
    const { crossSelling, fetch: fetchCrossSelling } = useProductCrossSelling(
      root,
      {}
    )

    const { addToWishlist } = useWishlist(root)

    const deleteCardSideModal = ref(false)
    const currentSelectedItemId = ref(null)

    const { isOpen: isNavigatorOpen } = useUIState(
      root,
      NAVIGATION_DRAWER_STATE
    )

    return {
      cartItems,
      deleteCardSideModal,
      totalPrice,
      currentSelectedItemId,
      removeItem,
      addToWishlist,
      appliedPromotionCodes,
      isNavigatorOpen,
      crossSelling,
      fetchCrossSelling,
      cartProductItems: computed(() => cartProductItems.value.reverse()),
      uiEventEditCart: useUiEvent(root, 'edit_cart', 'cart_overlay'),
      uiEventClickCrossSelling: useUiEvent(
        root,
        'cross_selling',
        'cart_overlay'
      ),
      uiEventFocusPromotion: useUiEvent(root, 'promotion', 'cart_overlay'),
      uiEventClose: useUiEvent(root, 'cart_overlay_close', 'cart_overlay'),
    }
  },
  data() {
    return {
      showAll: this.$vuetify.breakpoint.smAndDown,
      loading: false,
      booted: false,
    }
  },
  computed: {
    internalValue: {
      get() {
        return this.booted ? this.value : false
      },
      set(value) {
        this.$emit('input', value)
      },
    },
    shouldShowMoreButton() {
      return !this.showAll && this.cartProductItems.length > 2
    },
    drawerWidth() {
      return this.$vuetify.breakpoint.xsOnly ? '345px' : '400px'
    },
    limitedLineItems() {
      return this.showAll
        ? this.cartProductItems
        : this.cartProductItems.slice(0, 2)
    },
    individualisationItems() {
      return getLineItemProductIndividualisations(this.cartItems)
    },
    emptyCartUrl() {
      if (process.env.NUXT_ENV_BS_BUILD_MODULE === 'mabu') {
        return require('~/assets/images/cart/mb_empty_cart.jpg')
      } else {
        return require('~/assets/images/cart/empty_cart.jpg')
      }
    },
  },
  watch: {
    internalValue(isOpen) {
      if (isOpen) {
        if (
          this.cartProductItems.length > 0 &&
          (!this.crossSelling || !this.crossSelling.length)
        ) {
          this.fetchCrossSelling(this.cartProductItems[0]?.referencedId)
        }
      }
    },
  },
  mounted() {
    setTimeout(() => {
      this.booted = true
    }, 5)
  },
  methods: {
    handleRemoveCartItem(id) {
      this.deleteCardSideModal = true
      this.currentSelectedItemId = id
    },
    invokeRemoveCartItem(id) {
      this.removeItem({ id })
      this.deleteCardSideModal = false
    },
    invokeAddToWishlist(id) {
      this.addToWishlist(id)
      this.removeItem({ id })
      this.deleteCardSideModal = false
    },
    handleContinueShopping() {
      this.uiEventClose()
      this.internalValue = false
    },
  },
}
</script>
