<template>
  <div class="beige-pastel-light px-3 pt-3">
    <div class="d-flex justify-space-between">
      <span class="text-h6 text-sm-h5">{{ $t('commons.subtotal') }}:</span>
      <span class="text-h5 text-sm-h4">{{ subtotal | price }}</span>
    </div>
    <div class="text-body-5">
      {{ $t('commons.vatIncluded') }}
      <nuxt-link
        v-show="hasShippingCost"
        class="grey-darken-4--text text-decoration-none"
        :to="$t('links.serviceShippingAndPayments')"
        >{{ $t('commons.plusShipping') }}</nuxt-link
      >
    </div>

    <base-divider class="my-2"></base-divider>

    <product-detail-free-shipping-card></product-detail-free-shipping-card>

    <base-button
      large
      block
      bold
      class="mt-4 pink-berry white--text mx-n3"
      :to="localePath('/checkout')"
      @click.native="handleCheckout"
    >
      {{ $t('actions.goToCheckout') }}
    </base-button>
  </div>
</template>

<script>
import {
  useCart,
  useFreeShippingHint,
  useUiEvent,
  useUIState,
} from '~/composables'
import ProductDetailFreeShippingCard from '~/components/product/detail/ProductDetailFreeShippingCard'
import BaseDivider from '~/components/base/divider/BaseDivider'
import { CART_SIDEBAR_STATE } from '~/services/UiStates'
import BaseButton from '~/components/base/button/BaseButton'

export default {
  name: 'CartSideInfoMinimalCard',
  components: { BaseButton, BaseDivider, ProductDetailFreeShippingCard },
  setup(_, { root }) {
    const { subtotal } = useCart(root)
    const { hasShippingCost } = useFreeShippingHint(root)
    const { switchState: toggleSidebar } = useUIState(root, CART_SIDEBAR_STATE)

    return {
      subtotal,
      hasShippingCost,
      toggleSidebar,
      uiEventCheckout: useUiEvent(root, 'checkout', 'cart_overlay'),
    }
  },
  methods: {
    handleCheckout() {
      this.uiEventCheckout()
      this.toggleSidebar(false)
    },
  },
}
</script>
