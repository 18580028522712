<template>
  <div class="beige-pastel-light pa-3">
    <div class="d-flex justify-space-between">
      <span class="text-h6 text-sm-h5">{{ $t('commons.subtotal') }}:</span>
      <span class="text-h5 text-sm-h4">{{ subtotal | price }}</span>
    </div>
    <div class="text-body-5">
      {{ $t('commons.vatIncluded') }}
      <nuxt-link
        v-show="hasShippingCost"
        class="grey-darken-4--text text-decoration-none"
        :to="$t('links.serviceShippingAndPayments')"
        >{{ $t('commons.plusShipping') }}</nuxt-link
      >
    </div>

    <base-divider class="my-3"></base-divider>

    <product-detail-free-shipping-card></product-detail-free-shipping-card>

    <base-button
      large
      bold
      block
      class="mt-5 pink-berry white--text"
      rounded
      :to="localePath('/checkout')"
      @click.native="handleCheckout"
      >{{ $t('actions.goToCheckout') }}</base-button
    >

    <div class="d-flex justify-space-between mt-3">
      <base-button
        small
        outlined
        bold
        class="grey-darken-3--text"
        rounded
        :to="localePath('/cart')"
        @click.native="uiEventEditCart"
        >{{ $t('siteCart.editCart') }}</base-button
      >
      <payment-paypal-express-checkout-button
        style="min-width: 140px"
      ></payment-paypal-express-checkout-button>
    </div>
  </div>
</template>

<script>
import {
  useCart,
  useFreeShippingHint,
  useUiEvent,
  useUIState,
} from '~/composables'
import ProductDetailFreeShippingCard from '~/components/product/detail/ProductDetailFreeShippingCard'
import PaymentPaypalExpressCheckoutButton from '~/components/payment/paypal/PaymentPaypalExpressCheckoutButton'
import BaseDivider from '~/components/base/divider/BaseDivider'
import { CART_SIDEBAR_STATE } from '~/services/UiStates'
import BaseButton from '~/components/base/button/BaseButton'

export default {
  name: 'CartSideInfoCard',
  components: {
    BaseButton,
    BaseDivider,
    PaymentPaypalExpressCheckoutButton,
    ProductDetailFreeShippingCard,
  },
  setup(_, { root }) {
    const { subtotal } = useCart(root)
    const { hasShippingCost } = useFreeShippingHint(root)
    const { switchState: toggleSidebar } = useUIState(root, CART_SIDEBAR_STATE)

    return {
      subtotal,
      hasShippingCost,
      toggleSidebar,
      uiEventEditCart: useUiEvent(root, 'edit_cart', 'cart_overlay'),
      uiEventCheckout: useUiEvent(root, 'checkout', 'cart_overlay'),
    }
  },
  methods: {
    handleCheckout() {
      this.uiEventCheckout()
      this.toggleSidebar(false)
    },
  },
}
</script>
