<script>
export default {
  name: 'BaseDivider',
  functional: true,
  render(h, { data, children }) {
    data.staticClass = `base-divider ${data.staticClass || ''}`.trim()

    return h('hr', data, children)
  },
}
</script>
