<template>
  <div class="overflow-hidden">
    <div class="d-flex">
      <cart-product-item-image
        :url="url"
        :image-url="imageUrl"
        :individualisation="!!individualisation"
      ></cart-product-item-image>
      <div class="pl-3 d-flex flex-column justify-end w-full overflow-hidden">
        <nuxt-link
          class="
            text-h6
            d-block
            text-sm-h5 text-truncate text-decoration-none
            grey-darken-4--text
            overflow-hidden
          "
          :to="localePath(url)"
          >{{ title }}</nuxt-link
        >
        <span class="text-body-4">{{ optionName }}</span>

        <cart-low-stock
          v-if="stockLevel < 15 && stockLevel > 0"
          :stock="stockLevel"
          class="mt-1 w-max"
        ></cart-low-stock>

        <div class="mt-3 d-flex align-center">
          <base-input-stock-level
            v-show="item.stackable"
            v-model="quantity"
            :disable-decrement="1"
            class="mr-4"
          ></base-input-stock-level>
          <base-button icon @click="removeCartItem">
            <base-icon v-show="item.removable" mdi="delete"></base-icon>
          </base-button>
        </div>

        <cart-product-item-delivery
          class="mt-2"
          :item="item"
          :individualisation="individualisation"
        ></cart-product-item-delivery>
      </div>
    </div>

    <cart-product-item-individualisation
      v-if="individualisation"
      :individualisation="individualisation"
      class="mt-5"
    ></cart-product-item-individualisation>
  </div>
</template>

<script>
import {
  getCartItemCoverUrl,
  isLineItemBsFreeProductTypPseudo,
  joinCartItemPayloadOptions,
} from '~/helpers'
import { useCart, useUiEvent } from '~/composables'
import CartProductItemImage from '~/components/cart/CartProductItemImage'
import BaseInputStockLevel from '~/components/base/input/BaseInputStockLevel'
import CartLowStock from '~/components/cart/CartLowStock'
import CartProductItemDelivery from '~/components/cart/CartProductItemDelivery'
import CartProductItemIndividualisation from '~/components/cart/CartProductItemIndividualisation'
import BaseIcon from '~/components/base/icon/BaseIcon'
import BaseButton from '~/components/base/button/BaseButton'

export default {
  name: 'CartSideProductItem',
  components: {
    BaseButton,
    BaseIcon,
    CartProductItemIndividualisation,
    CartProductItemDelivery,
    CartLowStock,
    BaseInputStockLevel,
    CartProductItemImage,
  },
  props: {
    item: {
      type: Object,
      default: null,
    },
    individualisation: {
      type: Object,
      default: null,
    },
  },
  setup(_, { root }) {
    const { changeProductQuantity } = useCart(root)

    return {
      changeProductQuantity,
      uiEventRemoveItem: useUiEvent(root, 'remove_item', 'cart_overlay'),
      uiEventIncrementItem: useUiEvent(root, 'increment_item', 'cart_overlay'),
      uiEventDecrementItem: useUiEvent(root, 'decrement_item', 'cart_overlay'),
    }
  },
  computed: {
    quantity: {
      get() {
        return this.item.quantity
      },
      set(value) {
        if (value > this.quantity) {
          this.uiEventIncrementItem()
        } else {
          this.uiEventDecrementItem()
        }

        this.changeProductQuantity({
          id: this.item.id,
          quantity: value,
        })
      },
    },
    imageUrl() {
      return getCartItemCoverUrl(this.item)
    },
    title() {
      return this.item.label
    },
    url() {
      return isLineItemBsFreeProductTypPseudo(this.item)
        ? null
        : `/detail/${this.item.referencedId}`
    },
    stockLevel() {
      return this.item.deliveryInformation?.stock || 0
    },
    optionName() {
      return joinCartItemPayloadOptions(this.item)
    },
  },
  methods: {
    removeCartItem() {
      this.$emit('click:remove', this.item.id)
      this.uiEventRemoveItem()
    },
  },
}
</script>
